<template>

  <dimmer :active="submitting">
    <div class="table-responsive" style="max-height: 35rem; overflow-y: scroll;">
      <table class="table table-vcenter card-table dataTables_wrapper">
        <thead>
          <tr>
            <th
              class="text-center sticky-top bg-white"
              :class="column === 'n' ? `sorting_${sort}` : 'sorting'"
              @click="() => sortColumn('n')">
              #
            </th>
            <th class="text-center w-1 sticky-top bg-white">Photo</th>
            <th
              class="sticky-top bg-white"
              :class="column === 'name' ? `sorting_${sort}` : 'sorting'"
              @click="() => sortColumn('name', 'text')">
              Recipe
            </th>
            <th
              class="text-center sticky-top bg-white"
              :class="column === 'uptakesPercentage' ? `sorting_${sort}` : 'sorting'"
              @click="() => sortColumn('uptakesPercentage', 'number', 'items', 'uptakesPercentage')">
              Uptake
            </th>
            <th
              class="text-center sticky-top bg-white"
              :class="column === 'costAvg' ? `sorting_${sort}` : 'sorting'"
              @click="() => sortColumn('costAvg', 'number', 'items', 'costAvg')">
              Cost per serving (AED)
            </th>
            <th
              class="text-center sticky-top bg-white"
              :class="column === 'npsAvg' ? `sorting_${sort}` : 'sorting'"
              @click="() => sortColumn('npsAvg', 'number', 'items', 'npsAvg')">
              NPS
            </th>
            <th
              class="text-center sticky-top bg-white"
              :class="column === 'ratingAvg' ? `sorting_${sort}` : 'sorting'"
              @click="() => sortColumn('ratingAvg', 'number', 'items', 'ratingAvg')">
              Average ratings
            </th>
            <th
              class="text-center sticky-top bg-white"
              :class="column === 'totalRating' ? `sorting_${sort}` : 'sorting'"
              @click="() => sortColumn('totalRating')">
              Response rate
            </th>
            <th
              class="sticky-top bg-white"
              :class="column === 'frequency' ? `sorting_${sort}` : 'sorting'"
              @click="() => sortColumn('frequency')">
              Frequency
            </th>
            <th class="text-center sticky-top bg-white">Historical Data</th>
            <th class="w-1"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <td class="text-center">{{item.n}}</td>
            <td class="text-center">
              <a
                v-if="item.photo"
                :href="item.photo.url"
                target="_blank"
                rel="noreferrer noopener">
                <img
                  :src="getTransformationUrl(item.photo, {height: 40, width: 40, crop: 'fill'})"
                  alt=""
                  class="avatar avatar-md"/>
              </a>
            </td>
            <td>
              <router-link
                target="_blank"
                rel="noopener noreferrer"
                :to="`/recipes/${item.id}`">
                {{item.name}}
              </router-link>
              <div class="small note">{{item.mealPlan}}</div>
            </td>
            <td class="text-center" :class="{'text-danger': item.uptakesPercentage.color === 'red', 'text-success': item.uptakesPercentage.color === 'green'}">{{item.uptakesPercentage.uptakesPercentage}}%</td>
            <td class="text-center" :class="{'text-danger': item.costAvg.color === 'red', 'text-success': item.costAvg.color === 'green'}">{{item.costAvg.costAvg}}</td>
            <td class="text-center" :class="{'text-danger': item.npsAvg.color === 'red', 'text-success': item.npsAvg.color === 'green'}">{{numeral(item.npsAvg.npsAvg).format('0,0[.]00')}}%</td>
            <td class="text-center" :class="{'text-danger': item.ratingAvg.color === 'red', 'text-success': item.ratingAvg.color === 'green'}">{{numeral(item.ratingAvg.ratingAvg).format('0,0[.]00')}}</td>
            <td class="text-center">{{numeral(item.totalRating).format('0,0')}}</td>
            <td class="text-center">{{numeral(item.frequency).format('0,0')}}</td>
            <td class="text-nowrap">
              <ul>
                <li>
                  Uptakes: {{numeral(item.historicalUptakesPercentage).format('0,0[.]00')}}%
                </li>
                <li>
                  Rating: {{numeral(item.historicalRatingAvg).format('0,0[.]00')}}
                </li>
                <li>
                  NPS: {{numeral(item.historicalNpsAvg).format('0,0[.]00')}}%
                </li>
              </ul>
            </td>
            <td class="text-center">
              <div class="item-action dropdown">
                <a
                  tabindex="0"
                  data-toggle="dropdown"
                  class="icon"><i class="fe fe-more-vertical"></i></a>
                <div class="dropdown-menu dropdown-menu-right">
                  <router-link
                    :to="`/recipes/${item.id}?view=feedback`"
                    target="_blank"
                    rel="noreferrer noopener"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-message-circle"></i> View feedback
                  </router-link>
                  <a
                    :href="item.card"
                    target="_blank"
                    rel="noreferrer noopener"
                    class="dropdown-item">
                    <i class="dropdown-icon fe fe-book"></i> Preview card
                  </a>
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="items.length === 0">
            <td colspan="13">No recipe data is available for the selected period.</td>
          </tr>
        </tbody>
        <tfoot v-if="items.length" class="">
          <tr>
            <th></th>
            <th></th>
            <th class="text-right">Total:</th>
            <th class="text-center">{{numeral(getTotal('uptakesPercentage', 'uptakesPercentage')).format('0,0')}}%</th>
            <th class="text-center">{{numeral(getTotal('costAvg', 'costAvg')).format('0,0[.]00')}}</th>
            <th class="text-center">{{numeral(getTotal('npsAvg', 'npsAvg') / items.length).format('0.00')}}%</th>
            <th class="text-center">{{numeral(getTotal('ratingAvg', 'ratingAvg') / items.length).format('0.00')}}</th>
            <th class="text-center">{{numeral(getTotal('totalRating')).format('0,0')}}</th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th class="text-right">Average per portion:</th>
            <th></th>
            <th Class="text-center">{{numeral((getTotal('CostAvgPerPortion') - (items.find(item => item.n === 20).CostAvgPerPortion || 0)) / (getTotal('totalSelections') - (items.find(item => item.n === 20).totalSelections || 0))).format('0,0[.]00')}}</th>
            <th colspan="5"></th>
          </tr>
        </tfoot>
      </table>
    </div>
  </dimmer>

</template>

<script>

import {reports} from '@/services';
import sort from '@/mixins/sort';
import submitting from '@hellochef/shared-js/mixins/submitting';

export default {
  mixins: [

    sort,
    submitting,
  ],
  props: [

    'range',
  ],

  data() {
    return {

      items: [],
      key: null,
      totals: null,
    };
  },
  watch: {

    range: {

      deep: true,
      handler() {
        this.fetch();
      },
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {

    fetch() {
      const range = this.key = this.range;
      const params = {end: range.endDate.format('YYYY-MM-DD'), start: range.startDate.format('YYYY-MM-DD')};

      if (range.action !== 'all') { params.action = range.action; }

      if (range.boxSize !== 'all' || range.mealPlan !== 'all') {
        params.boxSize = range.boxSize;
        params.mealPlan = range.mealPlan;
      }

      this.items = [];

      this.submitIt(async () => {
        const data = await reports.getRecipePerformanceByWeek(params);

        if (this.key === range) { this.items = data; }
      });
    },
    getTotal(keyword, second) {
      if (second) { return this.items.reduce((count, data) => count + parseFloat(data[keyword][second]), 0); }

      return this.items.reduce((count, data) => count + parseFloat(data[keyword]), 0);
    },
  },
};

</script>
