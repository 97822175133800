<template>

  <div class="container">
    <tabs tab="recipes" active="recipes"></tabs>

    <div class="tab-pane active" role="tabpanel">
      <recipe-bank-profiles/>
      <div class="card">
        <div data-test="label-recipe-performance" class="card-header">
          <h3 class="card-title">Recipe performance by period</h3>
          <div class="card-options">
            <button
              v-if="can(uiPermissions.REPORTS_RECIPES_PERFORMANCE_EXPORT)"
              data-test="btn-export"
              class="btn btn-primary"
              @click="exportPerformance">
              Export
            </button>
          </div>
        </div>
        <div class="table-responsive">
          <recipe-performance-table :range="recipePerformanceTableByWeek"></recipe-performance-table>
        </div>
        <div class="card-footer form-row">
          <range-selector
            v-model="recipePerformanceTableByWeek"
            class="col mb-0"
            :periods="['calendar', 'month', 'week']"></range-selector>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import Tabs from '@/views/reports/Tabs';
import download from '@/mixins/download';
import {reports} from '@/services';
import RangeSelector from '@/views/reports/RangeSelector';
import RecipePerformanceTable from '@/views/reports/tables/RecipePerformanceTable';
import submitting from '@hellochef/shared-js/mixins/submitting';
import {thursdayBasedWeek} from '@hellochef/shared-js/helpers';
import moment from 'moment-timezone';
import RecipeBankProfiles from '@/views/reports/graphs/RecipeBankProfiles';

export default {
  name: 'RecipeDashboardReport',

  components: {

    RangeSelector,
    RecipePerformanceTable,
    Tabs,
    RecipeBankProfiles,
  },
  mixins: [

    download,
    submitting,
  ],
  data() {
    return {

      recipePerformanceTableByWeek: {

        endDate: thursdayBasedWeek(moment().add(-1, 'weeks').startOf('isoWeek')).add(6, 'days'),
        period: 'week',
        startDate: thursdayBasedWeek(moment().add(-1, 'weeks').startOf('isoWeek')),
      },
    };
  },
  methods: {

    exportBenchmark() {
      this.submitIt(async () => {
        this.download(await reports.recipeBenchmarkExport());
      });
    },
    exportPerformance() {
      this.submitIt(async () => {
        this.download(await reports.recipePerformanceExport(this.recipePerformanceTableByWeek));
      });
    },
  },
};

</script>
