<template>

  <div class="form-row">

    <div v-if="availablePeriods.length > 1" class="form-group col-4 mb-0">
      <label class="form-label">Period</label>
      <select
        v-model="period"
        data-test="label-period"
        class="form-control custom-select"
        required>
        <option
          v-for="item in availablePeriods"
          :key="item"
          :value="item">
          {{startCase(item)}}
        </option>
      </select>
    </div>
    <div v-if="period === 'week'" class="form-group col mb-0">
      <label v-if="value.hideWeekLabel" class="form-label">Week</label>
      <label v-else class="form-label">Week ({{moment(value.startDate).format('dd, D MMM YYYY')}} - {{moment(value.endDate).format('dd, D MMM YYYY')}})</label>
      <input
        v-model.lazy="week"
        type="number"
        class="form-control"
        required
        min="1"
        max="52">
    </div>
    <div v-else-if="period === 'month'" class="form-group col mb-0">
      <label class="form-label">Month</label>
      <select
        v-model="month"
        class="form-control custom-select"
        required>
        <option value="1">January</option>
        <option value="2">February</option>
        <option value="3">March</option>
        <option value="4">April</option>
        <option value="5">May</option>
        <option value="6">June</option>
        <option value="7">July</option>
        <option value="8">August</option>
        <option value="9">September</option>
        <option value="10">October</option>
        <option value="11">November</option>
        <option value="12">December</option>
      </select>
    </div>
    <calendar-selector
      v-else-if="period === 'calendar'"
      v-model="calendar"
      class="form-group col mb-0"></calendar-selector>
    <div v-if="period !== 'calendar'" class="form-group col mb-0">
      <label class="form-label">Year</label>
      <input
        v-model.lazy="year"
        type="number"
        class="form-control"
        required
        min="2000"
        :max="moment().year()">
    </div>

  </div>

</template>

<script>

import startCase from 'lodash/startCase';
import moment from 'moment-timezone';
import {thursdayBasedWeek} from '@hellochef/shared-js/helpers';
import CalendarSelector from '@/views/reports/CalendarSelector';

export default {

  components: {

    CalendarSelector,
  },
  props: [

    'periods',
    'value',
  ],
  data() {
    return {

      lang: {

        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        placeholder: {

          dateRange: 'Select date range',
        },
      },
    };
  },
  computed: {

    startCase() { return startCase; },
    availablePeriods() {
      return this.periods || ['calendar', 'week', 'month', 'year'];
    },
    calendar: {

      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
    month: {

      get() {
        return this.value.startDate.month() + 1;
      },
      set(newValue) {
        this.$emit('input', Object.assign({}, this.value, {

          endDate: moment().year(this.year).month(newValue - 1).endOf('month'),
          startDate: moment().year(this.year).month(newValue - 1).startOf('month'),
        }));
      },
    },
    period: {

      get() {
        return this.value.period;
      },
      set(newValue) {
        if (newValue === 'calendar') {
          this.$emit('input', Object.assign({}, this.value, {period: 'calendar'}));
        }
        else if (newValue === 'month') {
          const currentYear = moment().year();

          this.$emit('input', Object.assign({}, this.value, {

            endDate: moment().year(this.year).add(this.year === currentYear ? -1 : 0, 'months').endOf('month'),
            period: 'month',
            startDate: moment().year(this.year).add(this.year === currentYear ? -1 : 0, 'months').startOf('month'),
          }));
        }
        else if (newValue === 'week') {
          const currentYear = moment().year();

          this.$emit('input', Object.assign({}, this.value, {

            endDate: thursdayBasedWeek(moment().year(this.year).add(this.year === currentYear ? -1 : 0, 'weeks').startOf('isoWeek')).add(6, 'days'),
            period: 'week',
            startDate: thursdayBasedWeek(moment().year(this.year).add(this.year === currentYear ? -1 : 0, 'weeks').startOf('isoWeek')),
          }));
        }
        else if (newValue === 'year') {
          this.$emit('input', Object.assign({}, this.value, {

            endDate: moment().year(this.year).endOf('year'),
            period: 'year',
            startDate: moment().year(this.year).startOf('year'),
          }));
        }
      },
    },
    week: {

      get() {
        return this.value.startDate.clone().add(4, 'days').isoWeek(); // isoWeek starts on Monday.
      },
      set(newValue) {
        this.$emit('input', Object.assign({}, this.value, {

          endDate: thursdayBasedWeek(moment().year(this.year).isoWeek(newValue).startOf('isoWeek')).add(6, 'days'),
          startDate: thursdayBasedWeek(moment().year(this.year).isoWeek(newValue).startOf('isoWeek')),
        }));
      },
    },
    year: {

      get() {
        if (this.value.period === 'week' && this.week === 1) { return this.value.endDate.year(); }

        return this.value.startDate.year();
      },
      set(newValue) {
        if (this.value.period === 'month') {
          this.$emit('input', Object.assign({}, this.value, {

            endDate: moment().year(newValue).month(this.month - 1).endOf('month'),
            startDate: moment().year(newValue).month(this.month - 1).startOf('month'),
          }));
        }
        else if (this.value.period === 'week') {
          this.$emit('input', Object.assign({}, this.value, {

            endDate: thursdayBasedWeek(moment().year(newValue).isoWeek(this.week).startOf('isoWeek')).add(6, 'days'),
            startDate: thursdayBasedWeek(moment().year(newValue).isoWeek(this.week).startOf('isoWeek')),
          }));
        }
        else if (this.value.period === 'year') {
          this.$emit('input', Object.assign({}, this.value, {

            endDate: moment().year(newValue).endOf('year'),
            startDate: moment().year(newValue).startOf('year'),
          }));
        }
      },
    },
  },
};

</script>
