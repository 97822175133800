<template>

  <div>
    <label v-if="!value.hideLabel" class="form-label">Date range</label>
    <date-picker
      v-model="range"
      confirm
      input-class="form-control"
      :lang="lang"
      range
      range-separator="-"
      :format="value.format ? value.format : 'dddd, D MMM YYYY'"
      :shortcuts="[]"
      class="w-100"></date-picker>
  </div>

</template>

<script>

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment-timezone';

export default {

  components: {

    DatePicker,
  },
  props: [

    'value',
  ],
  data() {
    return {

      lang: {

        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        placeholder: {

          dateRange: 'Select date range',
        },
      },
    };
  },
  computed: {

    range: {

      get() {
        return [this.value.startDate.toDate(), this.value.endDate.toDate()];
      },
      set(newValue) {
        this.$emit('input', Object.assign({}, this.value, {

          endDate: moment(newValue[1]),
          startDate: moment(newValue[0]),
        }));
      },
    },
  },
};

</script>
