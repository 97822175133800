<template>
  <div class="card col-md-6">
    <div data-test="label-profiles" class="card-header">
      <i v-b-tooltip="'Published non archived recipes'" class="fe fe-info mr-2"></i> Recipe bank profiles
    </div>
    <div class="card-body">
      <vue-plotly :auto-resize="true" :data="data"/>
    </div>
  </div>
</template>

<script>
import VuePlotly from '@statnett/vue-plotly';
import {reports} from '@/services';

export default {
  name: 'RecipeBankProfiles',
  components: {
    VuePlotly,
  },
  data() {
    return {
      report: null,
      data: [
        {
          values: [],
          labels: [],
          type: 'pie',
          textinfo: 'label+percent',
        },
      ],
    };
  },
  async mounted() {
    const results = await reports.recipeBankProfiles();
    this.data[0].values = results.map(obj => obj.total);
    this.data[0].labels = results.map(obj => obj.name);
  },
};
</script>
