<template>

  <div class="page-header">
    <ul
      class="nav nav-tabs w-100 mx-0"
      style="margin-top: -1rem;"
      role="tablist">
      <template v-if="can(uiPermissions.REPORTS_RECIPES_VIEW)">
        <li class="nav-item">
          <router-link
            tabindex="0"
            to="/reports/recipes"
            class="nav-link"
            data-test="link-recipe-dashboard"
            :class="{'active': tab === 'recipes'}"
            role="tab">
            Recipe dashboard
          </router-link>
        </li>
      </template>
    </ul>
  </div>

</template>

<script>

export default {

  props: {
    tab: {
      type: String,
      required: true,
    },
  },
};

</script>
